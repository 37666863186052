import React from "react";

import Slider from "./Slider";

import styles from "./Hero.module.scss";

export const Hero = () => {
  const slides = [
    { video: "OozIQxYT8us" }
  ];

  return (
    <>
      <Slider slides={slides} className={styles.slider} interval={false} />
    </>
  );
};

export default Hero;
