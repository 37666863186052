import React from "react";
import { Container, Row, Col, Nav, Navbar, NavItem, NavLink } from "reactstrap";
import { FiMail, FiPhone, FiInstagram } from "react-icons/fi";

export const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <>
      <Container className="my-5 py-5">
        <Row className="mt-5 pt-4">
          <Col md="12" className="mx-auto h1 text-center mb-4">
            Contacto
          </Col>
          <Col md="8" className="mx-auto h2 mb-5 text-center">
            Sientanse libre de contactarnos en cualquier momento
          </Col>
        </Row>
        <Row className="mb-3 pb-2">
          <Col xs="auto" className="mx-auto h2">
            <a href="tel:+541126643261" className="text-dark d-block mb-4">
              <span className="mr-3">
                <FiPhone />
              </span>
              +54 11 2664 3261
            </a>
            <a
              href="mailto:team@moretape.com"
              className="text-dark d-block mb-4"
            >
              <span className="mr-3">
                <FiMail />
              </span>
              team@moretape.com
            </a>
            <a
              href="https://www.instagram.com/moretape.inc/"
              className="text-dark d-block mb-4 text-center"
            >
              <FiInstagram />
            </a>
          </Col>
        </Row>
      </Container>
      <Navbar className="mt-5 py-5" tag="footer" expand="md">
        <Container>
          <Nav className="mx-auto mx-md-0" navbar>
            <NavItem>Copyright © {year} Acc Bloomlab. All rights reserved.</NavItem>
          </Nav>
          <Nav className="mr-auto mr-md-0 ml-auto mt-4 mt-md-0" navbar>
            <NavItem>
              <NavLink
                href="https://instagram.com/moretape.inc"
                className="text-dark"
              >
                Design and build by{" "}
                <img
                  src="https://cdn.moretape.com/moretape/logos/logo.svg"
                  alt="Moretape - Everything you need to revolutionize your business"
                  height="30px"
                />
              </NavLink>
            </NavItem>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default Footer;
