import React from "react";
import logo from "./logo.png";
import styles from "./Logo.module.scss";

export const Logo = () => (
  <div>
    <img src={logo} alt="ACC BLOOMLAB" className={styles.logo} />
  </div>
);

export default Logo;
