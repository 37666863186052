import React from "react";
import { FiMail, FiPhone, FiInstagram } from "react-icons/fi";
import {
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  Row,
  Col
} from "reactstrap";

// Components
import Logo from "components/Logo";
import Hero from "components/Hero";
import Projects from "components/Projects";
import Shipping from "components/Shipping";
import Footer from "components/Footer";
import ProductsSlider from "components/ProductsSlider";

import styles from "./home.module.scss";

const TOTAL_PROJECTS = 13;

const getProjects = () => {
  let projects = [];
  
  for (let i = 1; i < TOTAL_PROJECTS; i++) {
    projects.push({
      image: `https://moretape-cdn.sfo2.digitaloceanspaces.com/accbloomlab/image_${i}.jpg`
    });
  }
  
  return projects;
};

const TOTAL_PRODUCTS = 9;
const projects = getProjects();

const getProducts = () => {
  let products = [];

  for (let i = 1; i < TOTAL_PRODUCTS; i++) {
    products.push({
      idx: i,
      image: `https://moretape-cdn.sfo2.digitaloceanspaces.com/accbloomlab/product.png`
    });
  }

  return products;
};

const products = getProducts();

export const HomePage = () => (
  <div>
    <Navbar className={styles.homeNavbar} expand="md">
      <Container className="position-relative">
        <NavbarBrand href="/" className="mx-md-auto">
          <Logo />
        </NavbarBrand>
        <Nav className={styles.nav} navbar>
          <NavItem className="mr-3">
            <NavLink href="tel:+541126643261" className="text-white">
              <FiPhone size="1.5em" />
            </NavLink>
          </NavItem>
          <NavItem className="mr-3">
            <NavLink href="mailto:team@moretape.com" className="text-white">
              <FiMail size="1.5em" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              href="https://www.instagram.com/moretape.inc/"
              className="text-white"
            >
              <FiInstagram size="1.5em" />
            </NavLink>
          </NavItem>
        </Nav>
      </Container>
    </Navbar>
    <Hero />
    <Shipping />
    <Container className="my-5 py-5">
      <Row>
        <Col md="12" className="mx-auto h2 text-center">
          El objetivo de Acc Bloomlab es mejorar la calidad de vida de millones de personas, 
          basándonos en las propiedades medicinales del CBD.
          En nuestra tienda online de puedes encontrar productos de CBD puro extraídos mediante la 
          técnica de extracción de evaporización de átomos.
        </Col>
      </Row>
    </Container>
    <div className={styles.productsSliderContainer}>
      <Container>
        <h1 className="text-center">PRODUCTOS</h1>
        <ProductsSlider products={products} />
      </Container>
    </div>
    <Projects projects={projects} />
    <Footer />
  </div>
);

export default HomePage;
