import React, { useState, useRef } from "react";
import YoutubeBackground from "react-youtube-background";
import Vimeo from "@u-wave/react-vimeo";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from "reactstrap";

import styles from "./Slider.module.scss";

const Slider = ({ children, slides = [], ...props }) => {
  const players = useRef({});
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const onReady = id => event => {
    if (event.play) {
      players.current[id] = {
        target: {
          playVideo: event.play.bind(event),
          pauseVideo: event.pause.bind(event)
        }
      };
    } else {
      players.current[id] = event;
    }
  };

  const switchPlayer = (activeIndex, nextIndex) => {
    const oldPlayer = players.current[activeIndex];
    const player = players.current[nextIndex];
    if (oldPlayer && oldPlayer.target) oldPlayer.target.pauseVideo();
    if (player && player.target) player.target.playVideo();
  };

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === slides.length - 1 ? 0 : activeIndex + 1;
    switchPlayer(activeIndex, nextIndex);
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? slides.length - 1 : activeIndex - 1;
    switchPlayer(activeIndex, nextIndex);
    setActiveIndex(nextIndex);
  };

  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const defaultPlayerOptions = {
    modestbranding: 1,
    rel: 0,
    loop: 1,
    controls: 0
  };

  const CarouselSlides = slides.length
    ? slides.map((slide, i) => {
        const playerOptions = {
          ...defaultPlayerOptions,
          autoplay: i === 1 ? 1 : 0
        };
        const isActive = i === activeIndex;
        const hasPlayerLoaded = players.current[i];
        const playerShouldLoad =
          (isActive && !hasPlayerLoaded && !animating) || hasPlayerLoaded;

        return (
          <CarouselItem
            className={styles.carouselItem}
            key={slide.src || slide.video || i}
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
          >
            {slide.video && playerShouldLoad ? (
              <>
                {slide.video && slide.video.type === "vimeo" ? (
                  <Vimeo
                    className={styles.video}
                    video={slide.video.id}
                    onReady={onReady(i)}
                    onEnd={next}
                    autoplay
                    background
                    responsive
                    muted
                    loop
                  />
                ) : (
                  <YoutubeBackground
                    videoId={slide.video}
                    className={"text-white h-100"}
                    playerOptions={playerOptions}
                    onReady={onReady(i)}
                    onEnd={next}
                  />
                )}
              </>
            ) : null}
            {slide.src ? <img src={slide.src} alt={slide.altText} /> : null}
            {slide.caption ? (
              <CarouselCaption
                captionText={slide.caption}
                captionHeader={slide.caption}
              />
            ) : null}
          </CarouselItem>
        );
      })
    : children;

  return (
    <Carousel
      {...props}
      activeIndex={activeIndex}
      next={next}
      previous={previous}
    >
      {slides.length > 1 ? (
        <CarouselIndicators
          items={slides}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
      ) : null}
      {CarouselSlides}
      {slides.length > 1 ? (
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
      ) : null}
      {slides.length > 1 ? (
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      ) : null}
    </Carousel>
  );
};

export default Slider;
