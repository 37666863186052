import "assets/scss/app.scss";

import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// pages
import HomePage from "pages/home";


function App() {
    return (
        <Router>
          <Switch>
            <Route path="/" component={HomePage} exact />
          </Switch>
        </Router>
      );
};

export default App;
