import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Row, Col, Card } from "reactstrap";

import styles from "./Projects.module.scss";

export const Projects = ({ projects }) => {
  let count = 0;

  return (
    <Row className="no-gutters">
      {projects.map((project, i) => {
        if (count === 3) count = 0;
        count++;

        return (
          <Col key={i} xs="12" md="4">
            <ScrollAnimation
              animateIn="fadeIn"
              delay={count * 200}
              animateOnce={true}
            >
              <Card
                className={styles.project}
                style={{
                  backgroundImage: `url(${project.image})`,
                  height: 400
                }}
              />
            </ScrollAnimation>
          </Col>
        );
      })}
    </Row>
  );
};

export default Projects;
