import React from 'react';
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import styles from "./ProductsSlider.module.scss";

SwiperCore.use([Navigation]);

export const ProductsSlider = ({ products }) => {
    return (
        <Swiper
            className={styles.swiperContainer}
            spaceBetween={0}
            slidesPerView={3}
            centeredSlides={true}
            loop
            watchSlidesVisibility
          >
            {products.map(({idx, image}) => {
              return (
                <SwiperSlide key={idx}>
                  <div
                    className={styles.productImage}
                    style={{ backgroundImage: `url(${image})`}}
                  />
                  <h3>CARLITOS KUSH</h3>
                </SwiperSlide>
              )
            })}
          </Swiper>
    );
}

export default ProductsSlider;