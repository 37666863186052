import React from "react";
import { Row, Col } from "reactstrap";
import styles from "./Shipping.module.scss";

export const Shipping = () => {
  const baseCdnUrl = "https://moretape-cdn.sfo2.digitaloceanspaces.com/accbloomlab"
  const shippingMainUrl = `${baseCdnUrl}/shipping-bg.jpg`
  const shippingThcUrl = `${baseCdnUrl}/pure.svg`
  const shippingGlobeUrl = `${baseCdnUrl}/globe-europe.svg`
  const shippingUmbrellaUrl = `${baseCdnUrl}/umbrella.svg`
  const shippingCustomUrl = `${baseCdnUrl}/customs.svg`
  const shippingMainStyle = {
    backgroundImage: `url(${ shippingMainUrl })`,
    backgroundRepeat  : 'no-repeat',
    backgroundPosition: 'center',
    width: '100%',
    margin: '0 auto',
    padding: '35px'
  }
  const shippingIconStyle = {
    width: '100%',
    height: '60px',
    content: "",
    display: 'block',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    verticalAlign: 'middle',
    backgroundSize: 'contain',
    marginBottom: '20px'
  }
  return (
    <div>
      <Row style={shippingMainStyle}>
        <Col xs="12" className="mx-auto text-center">
          <h2 className={styles.shipping}>ENVIO INTERNACIONAL</h2>
        </Col>
        <Col xs="12" md="3" className="mx-auto text-center mt-5">
          <div style={{backgroundImage: `url(${shippingThcUrl})`, ...shippingIconStyle}}/>
          <span className={styles.shipping}>MENOS DE 0.2% THC</span>
        </Col>
        <Col xs="12" md="3" className="mx-auto text-center mt-5">
          <div style={{backgroundImage: `url(${shippingGlobeUrl})`, ...shippingIconStyle}}/>
          <span className={styles.shipping}>DELIVERY DE 48H EN EUROPA</span>
        </Col>
        <Col xs="12" md="3" className="mx-auto text-center mt-5">
          <div style={{backgroundImage: `url(${shippingUmbrellaUrl})`, ...shippingIconStyle}}/>
          <span className={styles.shipping}>SEGURO DE ENVIO</span>
        </Col>
        <Col xs="12" md="3" className="mx-auto text-center mt-5">
          <div style={{backgroundImage: `url(${shippingCustomUrl})`, ...shippingIconStyle}}/>
          <span className={styles.shipping}>ADUANA EN ORDEN</span>
        </Col>
      </Row>
    </div>
  )
};

export default Shipping;
